import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'

import { TagLink, AuthorLink } from '../linkHelper'
import { H1 } from '../components/H123456'
import MainContent from '../components/mainContent'
import Post from '../components/postItems'
import Layout from '../components/layout'
import { formatDate } from '../dateUtils'

export default ({ location, data }) => (
  <Layout location={location}>
    <Helmet>
      <title>{data.contentfulPost.title}</title>
    </Helmet>
    <MainContent>
      <Row>
        <Col className='post-single'>
          <Col md='8' className='m-auto'>
            <div className='post-head'>
              <div className='post-tag'>
                {data.contentfulPost.tags.map(tag => (
                  <TagLink key={tag.slug} slug={tag.slug}>
                    {tag.name}
                  </TagLink>
                ))}
              </div>
              <H1 className='post-title'>{data.contentfulPost.title}</H1>
              <div className='post-meta'>
                <span className='author'>
                  <AuthorLink slug={data.contentfulPost.author.slug}>
                    {data.contentfulPost.author.name}
                  </AuthorLink>
                </span>
                <span className='date'>
                  {formatDate(data.contentfulPost.createdAt)}
                </span>
                <span className='reading-time'>
                  {data.contentfulPost.body.childMarkdownRemark.timeToRead} min
                  read
                </span>
              </div>
            </div>
          </Col>
          {data.contentfulPost.featuredImage != null ? (
            <Img
              fluid={data.contentfulPost.featuredImage.fluid}
              className='featured-image'
            />
          ) : null}
          <Col
            md='8'
            className='post-content-wrap m-auto'
            dangerouslySetInnerHTML={{
              __html: data.contentfulPost.body.childMarkdownRemark.html
            }}
          />
          <Col md='8' className='m-auto'>
            <Post.Sharer href={location.href} />
            <Post.AboutAuthor {...data.contentfulPost.author} />
            {/* <Post.PrevNext /> */}
            <Post.Commenter />
          </Col>
        </Col>
      </Row>
    </MainContent>
  </Layout>
)

export const pageQuery = graphql`
  query ContentfulPostBySlug($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      tags {
        slug
        name: title
      }
      author {
        ...AboutAuthor
      }
      featuredImage {
        fluid(
          maxWidth: 1500
          maxHeight: 844
          quality: 85
          resizingBehavior: FILL
          cropFocus: BOTTOM
        ) {
          ...GatsbyContentfulFluid
        }
      }
      body {
        childMarkdownRemark {
          html
          timeToRead
        }
      }
      createdAt
      updatedAt
    }
  }
`
